import React, { useEffect, useState } from 'react';
import {
    AttributeEditor,
    Autosuggest,
    Checkbox,
    ColumnLayout,
    CustomDetailEvent,
    FormField,
    FormSection,
    Input,
    RadioGroup,
    Select,
    Textarea,
} from '@amzn/awsui-components-react';
import {
    FORM_ERROR_SELECTOR,
    getStatusType,
} from '../../../../../imt/components/Instructor/FormSections/FormSections.common';
import {
    ActivityData,
    CommercialPrivateCustomer,
    Invoice,
} from '../../../../interfaces/activity';
import { LocationFormFieldText } from '../../../../../common/constants/forms';
import { useDispatch, useSelector } from 'react-redux';
import {
    getLocationsList,
    selectAllActiveLocations,
    selectError as selectLocationListError,
    selectIsLoaded as selectIsLocationListLoaded,
    selectIsLoading as selectIsLocationListLoading,
    selectSearchText as selectLocationListSearchText,
    setSearchText as setLocationListSearchText,
} from '../../../../../common/store/slices/locationsSlice';
import handlePreSelectedValue from '../../../../../common/utils/handlePreSelectedValue';
import { getCityString } from '../../../../../imt/components/Instructor/FormSections/BasicInfoFormSection';
import { LocationItemData } from '../../../../../common/interfaces/businessDataItem/locationItem';
import { currencyCodesOptions } from '../../../../constants/currencyCodes';
import { INVOICE_PAYMENT_TERM_KEYS } from '../../../../../common/constants/grimsby';
import { Alert } from '@amzn/awsui-components-react-v3';
import {
    getCountriesList,
    selectAllCountries,
    selectIsLoaded as selectIsCountiesLoaded,
    selectIsLoading as selectIsCountriesLoading,
} from '../../../../../common/store/slices/countriesSlice';
import { checkFeature } from '../../../../../common/utils/featureFlag';
import { selectFeatures } from '../../../../../common/store/slices/featureSlice';

interface InvoiceFormSectionProps {
    formValues: Invoice;
    errors: { [key in keyof Partial<Invoice>]: string };
    handleFieldEvent: (values: Partial<Invoice>) => void;
    activity?: ActivityData;
}

interface LineItem {
    item_type: string;
    item_amount: number;
}

const billingStatusOptions = [
    {
        label: 'Draft',
        id: 'Draft',
    },
    {
        label: 'To be billed',
        id: 'To be billed',
    },
    {
        label: 'To be billed - manually',
        id: 'To be billed - manually',
    },
    {
        label: 'To be accrued',
        id: 'To be accrued',
    },
    {
        label: 'Accrued',
        id: 'Accrued',
    },
    {
        label: 'Billed',
        id: 'Billed',
    },
];


const billingStatusOptionsV2 = [
    {
        label: 'Draft',
        id: 'Draft',
    },
    {
        label: 'To be billed',
        id: 'To be billed',
    },
    {
        label: 'Bill Immediately - Billing Console',
        id: 'Bill Immediately - Billing Console'
    },
    {
        label: 'To be billed - manually',
        id: 'To be billed - manually',
    },
    {
        label: 'To be accrued',
        id: 'To be accrued',
    },
    {
        label: 'Accrued',
        id: 'Accrued',
    },
    {
        label: 'Billed',
        id: 'Billed',
    },
];

const paymentTermOptions = Array.from(INVOICE_PAYMENT_TERM_KEYS.entries()).map(
    (termKeys) => {
        return {
            id: termKeys[0],
            label: termKeys[1],
        };
    },
);

const lineItemOptions = [
    { id: 'Private ILT class', label: 'Private ILT class' },
    { id: 'Public ILT class', label: 'Public ILT class' },
    { id: 'Travel & expense', label: 'Travel & expense' },
];

const currencyOptions = currencyCodesOptions;

export const getInvoiceCityString = (cityData: {
    bill_to_city: string | null;
    bill_to_state?: string;
    bill_to_country: string;
}) => {
    const {
        bill_to_city: city,
        bill_to_country: country,
        bill_to_state: state,
    } = cityData;
    return getCityString({
        city,
        country,
        state,
    });
};

export const InvoiceFormSection = ({
    formValues,
    errors,
    handleFieldEvent,
    activity,
}: InvoiceFormSectionProps) => {
    const citySearchText = useSelector(selectLocationListSearchText);
    const isLocationListLoading = useSelector(selectIsLocationListLoading);
    const isLocationListLoaded = useSelector(selectIsLocationListLoaded);
    const locationListError = useSelector(selectLocationListError);
    const originalLocationList = useSelector(selectAllActiveLocations);
    const countriesList = useSelector(selectAllCountries);
    const isCountriesListLoaded = useSelector(selectIsCountiesLoaded);
    const isCountriesListLoading = useSelector(selectIsCountriesLoading);
    const preselectedCityId = getInvoiceCityString(formValues);
    const shouldCheckForValue = !!(
        formValues.bill_to_city &&
        formValues.bill_to_state &&
        formValues.bill_to_country
    );
    const [customerSez, setCustomerSez] = useState(
        formValues.customer_sez_with_tax
            ? 'customer_sez_with_tax'
            : formValues.customer_sez
            ? 'customer_sez'
            : 'none',
    );

    const featureFlags = useSelector(selectFeatures);
    const isNMBSBillImmediatelyEnabled = checkFeature(
        '',
        { featureName: 'nmbs-billing-immediately'},
        featureFlags?.features);
    const dispatch = useDispatch();

    const locationList = handlePreSelectedValue(
        {
            city: formValues.bill_to_city as string,
            state: formValues.bill_to_state as string,
            country: formValues.bill_to_country,
            active: true,
            geo: '',
            region: '',
            pk: `location-${Date.now()}`,
            city_timezone: '',
        },
        shouldCheckForValue && isLocationListLoaded,
        originalLocationList,
        (location) => getCityString(location) === preselectedCityId,
    );

    // lifecycle method to fetch (and re-fetch) business data
    useEffect(() => {
        (
            [
                [
                    !isLocationListLoaded && !isLocationListLoading,
                    getLocationsList,
                ],
                [
                    !isCountriesListLoaded && !isCountriesListLoading,
                    getCountriesList,
                ],
            ] as ReadonlyArray<[boolean, Function]>
        ).forEach(([shouldFetch, getList]) => {
            if (shouldFetch) {
                dispatch(getList());
            }
        });
    });

    useEffect(() => {
        if (formValues.bill_to_country === 'India') {
            handleFieldEvent({
                customer_sez: customerSez === 'customer_sez',
                customer_sez_with_tax: customerSez === 'customer_sez_with_tax',
            });
        }

        if (countriesList.length && !!formValues.bill_to_country) {
            if (
                countriesList.filter(
                    (c) => c.country === formValues.bill_to_country,
                ).length === 0
            ) {
                handleFieldEvent({ bill_to_country: '' });
            }
        }
    }, [
        customerSez,
        handleFieldEvent,
        formValues.bill_to_country,
        countriesList,
    ]);

    const customerOptions = React.useMemo(() => {
        if (!activity?.customers) {
            return [];
        }
        return activity?.customers.map((customer) => {
            return {
                label: customer.sfdc_opportunity_id
                    ? `${customer.customer_name} (${customer.sfdc_opportunity_id})`
                    : customer.customer_name,
                id: customer.sfdc_opportunity_id
                    ? `${customer.customer_name}||${customer.sfdc_opportunity_id}`
                    : customer.customer_name,
            };
        });
    }, [activity]);

    const { citiesOptions, citiesEntityLookup } = locationList.reduce(
        (acc, city) => {
            const option = {
                label: getCityString(city),
                value: getCityString(city),
            } as Autosuggest.Option;
            acc.citiesEntityLookup[option.value] = city;
            acc.citiesOptionLookup[option.value] = option;

            const shouldAddOptionToList = !(
                citySearchText && option.value === preselectedCityId
            );

            if (shouldAddOptionToList) {
                acc.citiesOptions.push(option);
            }

            return acc;
        },
        {
            citiesOptions: [] as Array<Autosuggest.Option>,
            citiesOptionLookup: {} as {
                [key: string]: Autosuggest.Option;
            },
            citiesEntityLookup: {} as {
                [key: string]: LocationItemData;
            },
        },
    );

    const countryOptions = countriesList.length
        ? countriesList.map((country: any) => {
              return {
                  id: country.country,
                  label: country.country,
              };
          })
        : [
              {
                  id: formValues.bill_to_country,
                  label: formValues.bill_to_country,
              },
          ];

    const handleLocationDelayedFilteringChange = (
        e: CustomDetailEvent<Autosuggest.ChangeDetail>,
    ) => {
        dispatch(setLocationListSearchText(e.detail.value));
        dispatch(getLocationsList());
        handleFieldEvent({
            bill_to_city: e.detail.value,
        });
    };

    const handleLocationChange = (
        e: CustomDetailEvent<Autosuggest.ChangeDetail>,
    ) => {
        if (e.detail.selectedOption) {
            let city = citiesEntityLookup[e.detail.selectedOption.value];
            handleFieldEvent({
                bill_to_city: city.city,
                bill_to_country: city.country,
                bill_to_state: city.state,
            });
            dispatch(setLocationListSearchText(null));
        } else {
            handleFieldEvent({
                bill_to_city: e.detail.value,
            });
        }
    };

    const handleUpdateLineItemType = (itemType: string, index: number) => {
        let updatedLineItems = [...formValues.line_items];
        updatedLineItems[index].item_type = itemType;
        handleFieldEvent({
            line_items: updatedLineItems,
        });
    };

    const handleUpdateLineItemAmount = (itemAmount: string, index: number) => {
        let updatedLineItems = [...formValues.line_items];
        updatedLineItems[index].item_amount = parseFloat(itemAmount);
        handleFieldEvent({
            line_items: updatedLineItems,
        });
    };

    const handleCustomerChange = (
        customerId: string,
        onlyEndCustomer?: boolean,
    ) => {
        const customerIdParts = customerId.split('||');
        const customerName = customerIdParts[0];
        const customerSFDCId = customerIdParts[1] ?? null;
        // add billed items from revenues for this customer
        let customerLookup = activity?.customers
            .filter((c) => {
                return (
                    c.customer_name === customerName &&
                    c.sfdc_opportunity_id === customerSFDCId
                );
            })
            .pop();

        if (!customerLookup) {
            return;
        }

        let customer: CommercialPrivateCustomer = customerLookup;

        if (!onlyEndCustomer) {
            handleFieldEvent({
                customer_aws_account_id:
                    customer?.customer_aws_account_id ??
                    formValues.customer_aws_account_id,
                bill_to_aws_account_id:
                    customer?.customer_aws_account_id ??
                    formValues.customer_aws_account_id,
                sfdc_opportunity_id:
                    customer?.sfdc_opportunity_id ??
                    formValues.sfdc_opportunity_id,
                number_of_participants:
                    customer?.number_of_students_attended ??
                    formValues.number_of_participants,
                currency: customer?.currency ?? formValues.currency,
            });
        } else {
            handleFieldEvent({
                customer_aws_account_id:
                    customer?.customer_aws_account_id ??
                    formValues.customer_aws_account_id,
            });
        }

        if (customer?.revenues) {
            let lineItems: Array<LineItem> = customer.revenues
                .filter((revItem) => ['Billed', 'TnE'].includes(revItem.type))
                .map((revenue) => {
                    return {
                        item_amount: revenue.amount,
                        item_type:
                            revenue.type === 'TnE'
                                ? 'Travel & expense'
                                : activity?.activity_audience === 'Public'
                                ? 'Public ILT class'
                                : 'Private ILT class',
                    };
                });

            handleFieldEvent({
                line_items: lineItems,
            });
        }
    };

    return (
        <FormSection
            data-testid="EditInvoiceDetailsFormSection"
            header="Invoice details"
        >
            <ColumnLayout>
                <div data-awsui-column-layout-root="true">
                    <FormField
                        errorText={errors?.billing_status}
                        label="Billing status"
                    >
                        <Select
                            className={
                                errors?.billing_status && FORM_ERROR_SELECTOR
                            }
                            placeholder={'Select a billing status'}
                            options={isNMBSBillImmediatelyEnabled ? billingStatusOptionsV2: billingStatusOptions}
                            selectedOption={
                                !!formValues.billing_status
                                    ? {
                                          label: formValues.billing_status,
                                          id: formValues.billing_status,
                                      }
                                    : {
                                          label: 'Draft',
                                          id: 'Draft',
                                      }
                            }
                            onChange={(e) => {
                                handleFieldEvent({
                                    billing_status:
                                        e.detail.selectedOption.label,
                                });
                            }}
                            data-testid={`EditInvoiceBillingStatus`}
                        />
                    </FormField>
                    <FormField
                        errorText={errors?.ofa_invoice_number}
                        label={
                            <span>
                                Invoice number - <em>optional</em>
                            </span>
                        }
                        description="The invoice number sent to customer."
                    >
                        <Input
                            className={
                                errors?.ofa_invoice_number &&
                                FORM_ERROR_SELECTOR
                            }
                            value={formValues.ofa_invoice_number}
                            placeholder="Invoice number"
                            onInput={(e) =>
                                handleFieldEvent({
                                    ofa_invoice_number: e.detail.value,
                                })
                            }
                            data-testid={`EditInvoiceNumber`}
                            disabled={
                                !(
                                    formValues.billing_status === 'Billed' ||
                                    formValues.billing_status === 'Accrued'
                                ) || !!formValues.s3_path
                            }
                        />
                    </FormField>
                    <FormField
                        label="Is company a reseller?"
                        errorText={errors?.is_reseller}
                    >
                        <RadioGroup
                            data-testid="is-reseller-radio"
                            onChange={({ detail }) => {
                                handleFieldEvent({
                                    is_reseller:
                                        detail.value === 'true' ? true : false,
                                });

                                if (detail.value === 'true') {
                                    handleFieldEvent({
                                        customer_aws_account_id: '',
                                    });
                                } else {
                                    handleFieldEvent({
                                        customer_aws_account_id:
                                            formValues.bill_to_aws_account_id,
                                    });
                                }
                            }}
                            value={`${formValues.is_reseller}`}
                            items={[
                                { value: 'true', label: 'Yes' },
                                { value: 'false', label: 'No' },
                            ]}
                        />
                    </FormField>
                    <FormField
                        label="Company name to be invoiced"
                        errorText={errors?.customer_name}
                    >
                        {formValues.is_reseller && (
                            <Input
                                className={
                                    errors?.customer_name && FORM_ERROR_SELECTOR
                                }
                                value={formValues.customer_name}
                                placeholder="Company name to be invoiced"
                                onInput={(e) =>
                                    handleFieldEvent({
                                        customer_name: e.detail.value,
                                    })
                                }
                                data-testid={`EditCustomerName`}
                            />
                        )}
                        {!formValues.is_reseller && (
                            <Select
                                className={
                                    errors?.customer_name && FORM_ERROR_SELECTOR
                                }
                                placeholder={'Select a customer'}
                                options={customerOptions}
                                selectedOption={
                                    !!formValues.customer_name
                                        ? {
                                              label: formValues.customer_name,
                                              id: formValues.customer_name,
                                          }
                                        : null
                                }
                                onChange={(e) => {
                                    handleFieldEvent({
                                        customer_name:
                                            e.detail.selectedOption.label,
                                    });
                                    handleCustomerChange(
                                        e.detail.selectedOption.id,
                                    );
                                }}
                                data-testid={`EditCustomerName`}
                            />
                        )}
                    </FormField>
                    <FormField
                        label="Bill to AWS account ID"
                        description="The company account to be billed."
                        errorText={errors?.bill_to_aws_account_id}
                    >
                        <Input
                            className={
                                errors?.bill_to_aws_account_id &&
                                FORM_ERROR_SELECTOR
                            }
                            value={formValues.bill_to_aws_account_id}
                            placeholder="Bill to AWS account ID"
                            onInput={(e) => {
                                handleFieldEvent({
                                    bill_to_aws_account_id: e.detail.value,
                                });

                                if (!formValues.is_reseller) {
                                    handleFieldEvent({
                                        customer_aws_account_id: e.detail.value,
                                    });
                                }
                            }}
                            data-testid={`EditBillToAWSAccountID`}
                        />
                    </FormField>
                    {formValues.is_reseller === true && (
                        <FormField
                            label={
                                <span>
                                    End customer name - <em>optional</em>
                                </span>
                            }
                            description="Associate this invoice with a customer."
                            errorText={errors?.customer_legal_name}
                        >
                            <Select
                                className={
                                    errors?.customer_legal_name &&
                                    FORM_ERROR_SELECTOR
                                }
                                options={customerOptions}
                                selectedOption={
                                    !!formValues.customer_legal_name
                                        ? {
                                              label: formValues.customer_legal_name,
                                              id: formValues.customer_legal_name,
                                          }
                                        : null
                                }
                                placeholder="End customer name"
                                onChange={(e) => {
                                    handleFieldEvent({
                                        customer_legal_name:
                                            e.detail.selectedOption.label,
                                    });
                                    handleCustomerChange(
                                        e.detail.selectedOption.id,
                                        true,
                                    );
                                }}
                                data-testid={`EditCustomerName`}
                            />
                        </FormField>
                    )}
                    <FormField
                        label={
                            formValues.is_reseller ? (
                                <span>
                                    End customer AWS account ID -{' '}
                                    <em>optional</em>
                                </span>
                            ) : (
                                <span>End customer AWS account ID</span>
                            )
                        }
                        description="The company who received the service."
                        errorText={errors?.customer_aws_account_id}
                    >
                        <Input
                            className={
                                errors?.customer_aws_account_id &&
                                FORM_ERROR_SELECTOR
                            }
                            value={formValues.customer_aws_account_id}
                            placeholder="End customer AWS account ID"
                            onInput={(e) =>
                                handleFieldEvent({
                                    customer_aws_account_id: e.detail.value,
                                })
                            }
                            data-testid={`EditEndCustomerAWSAccountID`}
                        />
                    </FormField>
                    <FormField
                        label={
                                <span>
                                    Source customer account ID -{' '}
                                    <em>optional</em>
                                </span>
                        }
                        description="Only used when a customer has multiple invoices from other regions within the same month."
                        errorText={errors?.source_customer_id}
                    >
                        <Input
                            className={
                                errors?.source_customer_id &&
                                FORM_ERROR_SELECTOR
                            }
                            value={formValues.source_customer_id}
                            placeholder="Unique ID for region"
                            onInput={(e) =>
                                handleFieldEvent({
                                    source_customer_id: e.detail.value,
                                })
                            }
                            data-testid={`EditSourceCustomerID`}
                />
	                     </FormField>
                    <FormField
                        label="Payment terms"
                        errorText={errors?.payment_terms}
                    >
                        <Select
                            className={
                                errors?.payment_terms && FORM_ERROR_SELECTOR
                            }
                            placeholder={'Payment terms'}
                            options={paymentTermOptions}
                            selectedOption={paymentTermOptions
                                .filter(
                                    (t) => t.id === formValues.payment_terms,
                                )
                                .pop()}
                            onChange={(e) => {
                                handleFieldEvent({
                                    payment_terms: e.detail.selectedOption.id,
                                });
                            }}
                            onRecoveryClick={() => {}}
                            data-testid={`EditInvoicePaymentTerms`}
                        />
                    </FormField>
                    <FormField
                        label={
                            <span>
                                Tax ID - <em>optional</em>
                            </span>
                        }
                        errorText={errors?.bill_to_tax_id}
                    >
                        <Input
                            className={
                                errors?.bill_to_tax_id && FORM_ERROR_SELECTOR
                            }
                            value={formValues.bill_to_tax_id}
                            placeholder="Tax ID"
                            onInput={(e) =>
                                handleFieldEvent({
                                    bill_to_tax_id: e.detail.value,
                                })
                            }
                            data-testid={`EditInvoiceTaxID`}
                        />
                    </FormField>
                    <FormField
                        label={
                            <span>
                                PO number - <em>optional</em>
                            </span>
                        }
                        errorText={errors?.po_number}
                    >
                        <Input
                            className={errors?.po_number && FORM_ERROR_SELECTOR}
                            value={formValues.po_number}
                            placeholder="PO number"
                            onInput={(e) =>
                                handleFieldEvent({
                                    po_number: e.detail.value,
                                })
                            }
                            data-testid={`EditInvoicePONumber`}
                        />
                    </FormField>
                    <FormField
                        label="Number of students attended"
                        errorText={errors?.number_of_participants}
                    >
                        <Input
                            className={
                                errors?.number_of_participants &&
                                FORM_ERROR_SELECTOR
                            }
                            value={`${formValues.number_of_participants}`}
                            placeholder="Number of students attended"
                            onInput={(e) =>
                                handleFieldEvent({
                                    number_of_participants: parseInt(
                                        e.detail.value,
                                    ),
                                })
                            }
                            data-testid={`EditInvoicePONumber`}
                            type="number"
                        />
                    </FormField>
                    <FormField
                        label="Prepay"
                        description="Has the company already paid?"
                        errorText={errors?.prepay}
                    >
                        <RadioGroup
                            data-testid="is-company-paid-radio"
                            onChange={({ detail }) =>
                                handleFieldEvent({
                                    prepay:
                                        detail.value === 'true' ? true : false,
                                })
                            }
                            value={`${formValues.prepay}`}
                            items={[
                                { value: 'true', label: 'Yes' },
                                { value: 'false', label: 'No' },
                            ]}
                        />
                    </FormField>
                    {formValues.prepay === true && (
                        <FormField
                            label="Request for funds (RFF) ID"
                            description="RFF ID from the original prepayment amount in Clipper."
                            errorText={errors?.request_for_funds_id}
                        >
                            <Input
                                className={
                                    errors?.request_for_funds_id &&
                                    FORM_ERROR_SELECTOR
                                }
                                value={formValues.request_for_funds_id}
                                placeholder="Request for funds (RFF) ID"
                                onInput={(e) =>
                                    handleFieldEvent({
                                        request_for_funds_id: e.detail.value,
                                    })
                                }
                                data-testid={`EditInvoiceRFFID`}
                            />
                        </FormField>
                    )}
                    <FormField
                        label={
                            <span>
                                SFDC opportunity ID - <em>optional</em>
                            </span>
                        }
                        errorText={errors?.sfdc_opportunity_id}
                    >
                        <Input
                            className={
                                errors?.sfdc_opportunity_id &&
                                FORM_ERROR_SELECTOR
                            }
                            value={formValues.sfdc_opportunity_id}
                            placeholder="SFDC opportunity ID"
                            onInput={(e) =>
                                handleFieldEvent({
                                    sfdc_opportunity_id: e.detail.value,
                                })
                            }
                            data-testid={`EditInvoiceSFDCOpportunityID`}
                        />
                    </FormField>
                    <FormField
                        label={
                            <span>
                                Customer invoice notes - <em>optional</em>
                            </span>
                        }
                        errorText={errors?.customer_invoice_notes}
                    >
                        <Textarea
                            className={
                                errors?.customer_invoice_notes &&
                                FORM_ERROR_SELECTOR
                            }
                            value={formValues.customer_invoice_notes}
                            placeholder="Customer invoice notes - optional"
                            onInput={(e) =>
                                handleFieldEvent({
                                    customer_invoice_notes: e.detail.value,
                                })
                            }
                            data-testid={`EditInvoiceNotes`}
                        />
                    </FormField>
                    <Checkbox
                        data-testid="is-invoice-grouped"
                        onChange={({ detail }) =>
                            handleFieldEvent({
                                do_not_group: detail.checked,
                            })
                        }
                        checked={formValues.do_not_group ? true : false}
                    >
                        Don't group with this customers other invoices.
                    </Checkbox>
                    <div className="grimsby-sub-section-divider" />
                    <h3>Billing contact info</h3>
                    <FormField
                        label="First name"
                        errorText={errors?.bill_to_first_name}
                    >
                        <Input
                            className={
                                errors?.bill_to_first_name &&
                                FORM_ERROR_SELECTOR
                            }
                            value={formValues.bill_to_first_name}
                            placeholder="First name"
                            onInput={(e) =>
                                handleFieldEvent({
                                    bill_to_first_name: e.detail.value,
                                })
                            }
                            data-testid={`EditInvoiceBillingFirstName`}
                        />
                    </FormField>
                    <FormField
                        label="Last name"
                        errorText={errors?.bill_to_last_name}
                    >
                        <Input
                            className={
                                errors?.bill_to_last_name && FORM_ERROR_SELECTOR
                            }
                            value={formValues.bill_to_last_name}
                            placeholder="Last name"
                            onInput={(e) =>
                                handleFieldEvent({
                                    bill_to_last_name: e.detail.value,
                                })
                            }
                            data-testid={`EditInvoiceBillingLastName`}
                        />
                    </FormField>
                    <FormField label="Email" errorText={errors?.bill_to_email}>
                        <Input
                            className={
                                errors?.bill_to_email && FORM_ERROR_SELECTOR
                            }
                            value={formValues.bill_to_email}
                            placeholder="Email"
                            onInput={(e) =>
                                handleFieldEvent({
                                    bill_to_email: e.detail.value,
                                })
                            }
                            data-testid={`EditInvoiceBillingEmail`}
                        />
                    </FormField>
                    <FormField
                        label="Address 1"
                        errorText={errors?.bill_to_address_1}
                    >
                        <Input
                            className={
                                errors?.bill_to_address_1 && FORM_ERROR_SELECTOR
                            }
                            value={formValues.bill_to_address_1}
                            placeholder="Address 1"
                            onInput={(e) =>
                                handleFieldEvent({
                                    bill_to_address_1: e.detail.value,
                                })
                            }
                            data-testid={`EditInvoiceBillingAddress1`}
                        />
                    </FormField>
                    <FormField
                        label="Address 2 - optional"
                        errorText={errors?.bill_to_address_2}
                    >
                        <Input
                            className={
                                errors?.bill_to_address_2 && FORM_ERROR_SELECTOR
                            }
                            value={formValues.bill_to_address_2}
                            placeholder="Address 2 - optional"
                            onInput={(e) =>
                                handleFieldEvent({
                                    bill_to_address_2: e.detail.value,
                                })
                            }
                            data-testid={`EditInvoiceBillingAddress2`}
                        />
                    </FormField>
                    <FormField label="City" errorText={errors?.bill_to_city}>
                        <Autosuggest
                            className={
                                errors?.bill_to_city && FORM_ERROR_SELECTOR
                            }
                            empty={LocationFormFieldText.Empty}
                            loadingText={LocationFormFieldText.Loading}
                            filteringType="manual"
                            errorText={LocationFormFieldText.Error}
                            recoveryText={LocationFormFieldText.Recovery}
                            statusType={getStatusType(
                                isLocationListLoading,
                                isLocationListLoaded,
                                locationListError,
                            )}
                            options={citiesOptions}
                            value={formValues.bill_to_city}
                            placeholder={
                                isLocationListLoading
                                    ? LocationFormFieldText.Loading
                                    : 'City'
                            }
                            onChange={handleLocationChange}
                            onDelayedInput={(e) => {
                                handleLocationDelayedFilteringChange(e);
                            }}
                            data-testid={`EditInvoiceCity`}
                            disableBrowserAutocorrect={true}
                        />
                    </FormField>
                    <FormField label="State" errorText={errors?.bill_to_state}>
                        <Input
                            className={
                                errors?.bill_to_state && FORM_ERROR_SELECTOR
                            }
                            value={formValues.bill_to_state}
                            placeholder="State"
                            onInput={(e) =>
                                handleFieldEvent({
                                    bill_to_state: e.detail.value,
                                })
                            }
                            data-testid={`EditInvoiceState`}
                        />
                    </FormField>
                    <FormField
                        label="Postal code"
                        errorText={errors?.bill_to_postal_code}
                    >
                        <Input
                            className={
                                errors?.bill_to_postal_code &&
                                FORM_ERROR_SELECTOR
                            }
                            value={formValues.bill_to_postal_code}
                            placeholder="Postal code"
                            onInput={(e) =>
                                handleFieldEvent({
                                    bill_to_postal_code: e.detail.value,
                                })
                            }
                            data-testid={`EditInvoiceBillingPostalCode`}
                        />
                    </FormField>
                    <FormField
                        label="Country"
                        errorText={errors?.bill_to_country}
                    >
                        <Select
                            className={
                                errors?.bill_to_country && FORM_ERROR_SELECTOR
                            }
                            selectedOption={{
                                id: formValues.bill_to_country,
                                label: formValues.bill_to_country,
                            }}
                            placeholder="Country"
                            onChange={(e) =>
                                handleFieldEvent({
                                    bill_to_country:
                                        e.detail.selectedOption.label,
                                })
                            }
                            filteringType="auto"
                            data-testid={`EditInvoiceCountry`}
                            options={countryOptions}
                        />
                    </FormField>
                    {formValues.bill_to_country === 'Italy' && (
                        <>
                            <FormField
                                label="SDI account ID"
                                errorText={errors?.sdi_account_id}
                            >
                                <Input
                                    className={
                                        errors?.sdi_account_id &&
                                        FORM_ERROR_SELECTOR
                                    }
                                    value={formValues.sdi_account_id}
                                    placeholder="SDI account ID"
                                    onInput={(e) =>
                                        handleFieldEvent({
                                            sdi_account_id: e.detail.value,
                                        })
                                    }
                                    data-testid={`EditInvoiceSDIAccountID`}
                                />
                            </FormField>
                            <FormField
                                label="SDI email address"
                                errorText={errors?.sdi_email_address}
                            >
                                <Input
                                    className={
                                        errors?.sdi_email_address &&
                                        FORM_ERROR_SELECTOR
                                    }
                                    value={formValues.sdi_email_address}
                                    placeholder="SDI account ID"
                                    onInput={(e) =>
                                        handleFieldEvent({
                                            sdi_email_address: e.detail.value,
                                        })
                                    }
                                    data-testid={`EditInvoiceSDIEmailAddress`}
                                />
                            </FormField>
                        </>
                    )}
                    {formValues.bill_to_country === 'India' && (
                        <>
                            <FormField
                                label="Goods and services tax (GST) ID"
                                errorText={errors?.gst_tax_id}
                            >
                                <Input
                                    className={
                                        errors?.gst_tax_id &&
                                        FORM_ERROR_SELECTOR
                                    }
                                    value={formValues.gst_tax_id}
                                    placeholder="Goods and services tax (GST) ID"
                                    onInput={(e) =>
                                        handleFieldEvent({
                                            gst_tax_id: e.detail.value,
                                        })
                                    }
                                    data-testid={`EditInvoiceGSTTaxID`}
                                />
                            </FormField>
                            <FormField
                                errorText={errors?.customer_event_agency}
                            >
                                <Checkbox
                                    className={
                                        errors?.customer_event_agency &&
                                        FORM_ERROR_SELECTOR
                                    }
                                    checked={!!formValues.customer_event_agency}
                                    label="Customer event agency"
                                    onChange={(e) =>
                                        handleFieldEvent({
                                            customer_event_agency:
                                                e.detail.checked,
                                        })
                                    }
                                    data-testid={`EditInvoiceCustomerEventAgency`}
                                />
                            </FormField>
                            <FormField
                                errorText={errors?.customer_non_body_corporate}
                            >
                                <Checkbox
                                    className={
                                        errors?.customer_non_body_corporate &&
                                        FORM_ERROR_SELECTOR
                                    }
                                    checked={
                                        !!formValues.customer_non_body_corporate
                                    }
                                    label="Customer non-body corporate"
                                    onChange={(e) =>
                                        handleFieldEvent({
                                            customer_non_body_corporate:
                                                e.detail.checked,
                                        })
                                    }
                                    data-testid={`EditInvoiceCustomerEventAgency`}
                                />
                            </FormField>
                            <FormField
                                label="Special economic zone (SEZ)"
                                errorText={errors?.customer_sez}
                            >
                                <RadioGroup
                                    onChange={({ detail }) => {
                                        setCustomerSez(detail.value);
                                    }}
                                    value={`${customerSez}`}
                                    items={[
                                        { value: 'none', label: 'None' },
                                        {
                                            value: 'customer_sez',
                                            label: 'Customer SEZ',
                                        },
                                        {
                                            value: 'customer_sez_with_tax',
                                            label: 'Customer SEZ with tax',
                                        },
                                    ]}
                                    data-testid={`EditInvoiceCustomerSez`}
                                />
                            </FormField>
                        </>
                    )}
                    {formValues.bill_to_country === 'Canada' && (
                        <>
                            <FormField errorText={errors?.gst_hst_applicable}>
                                <Checkbox
                                    className={
                                        errors?.gst_hst_applicable &&
                                        FORM_ERROR_SELECTOR
                                    }
                                    checked={!!formValues.gst_hst_applicable}
                                    label="Goods and services tax (GST) / Harmonized sales tax (HST) applicable"
                                    onChange={(e) =>
                                        handleFieldEvent({
                                            gst_hst_applicable:
                                                e.detail.checked,
                                        })
                                    }
                                    data-testid={`EditInvoiceGSTHSTApplicable`}
                                />
                            </FormField>
                            <FormField errorText={errors?.pst_applicable}>
                                <Checkbox
                                    className={
                                        errors?.pst_applicable &&
                                        FORM_ERROR_SELECTOR
                                    }
                                    checked={!!formValues.pst_applicable}
                                    label="Provencial sales tax (PST) applicable"
                                    onChange={(e) =>
                                        handleFieldEvent({
                                            pst_applicable: e.detail.checked,
                                        })
                                    }
                                    data-testid={`EditInvoicePSTApplicable`}
                                />
                            </FormField>
                        </>
                    )}
                    <div className="grimsby-sub-section-divider" />
                    <h3>Line items</h3>
                    <FormField label="Currency" errorText={errors?.currency}>
                        <Select
                            className={errors?.currency && FORM_ERROR_SELECTOR}
                            placeholder={'Currency'}
                            options={currencyOptions}
                            filteringType="auto"
                            selectedOption={
                                !!formValues.currency
                                    ? {
                                          label: formValues.currency,
                                          id: formValues.currency,
                                      }
                                    : { label: 'USD', id: 'USD' }
                            }
                            onChange={(e) => {
                                handleFieldEvent({
                                    currency: e.detail.selectedOption.label,
                                });
                            }}
                            disabled={!formValues.is_reseller}
                            onRecoveryClick={() => {}}
                            data-testid={`EditInvoiceCurrency`}
                        />
                    </FormField>
                    {!!errors.line_items && (
                        <Alert type="error">
                            Must include at least one line-item.
                        </Alert>
                    )}
                    <AttributeEditor
                        disableAddButton={!formValues.is_reseller}
                        onAddButtonClick={() => {
                            handleFieldEvent({
                                line_items: formValues.line_items
                                    ? [
                                          ...formValues.line_items,
                                          {
                                              item_type: 'Travel & expense',
                                              item_amount: 0,
                                          },
                                      ]
                                    : [
                                          {
                                              item_type: 'Travel & expense',
                                              item_amount: 0,
                                          },
                                      ],
                            });
                        }}
                        onRemoveButtonClick={({ detail: { itemIndex } }) => {
                            const tmpItems = [...formValues.line_items];
                            tmpItems.splice(itemIndex, 1);
                            handleFieldEvent({
                                line_items: tmpItems,
                            });
                        }}
                        items={formValues?.line_items ?? []}
                        definition={[
                            {
                                label: 'Line item type',
                                control: (item, itemIndex) =>
                                    formValues.is_reseller ? (
                                        <Select
                                            data-testid="line-item-type"
                                            placeholder={'Line item type'}
                                            options={lineItemOptions}
                                            selectedOption={{
                                                label: item.item_type,
                                                id: item.item_type,
                                            }}
                                            disabled={!formValues.is_reseller}
                                            onChange={(e) => {
                                                handleUpdateLineItemType(
                                                    e?.detail.selectedId,
                                                    itemIndex,
                                                );
                                            }}
                                        />
                                    ) : (
                                        <Input
                                            data-testid="line-item-type"
                                            placeholder={'Line item type'}
                                            value={item.item_type}
                                            readonly={true}
                                        />
                                    ),
                            },
                            {
                                label: 'Line item amount',
                                control: (item, itemIndex) => (
                                    <Input
                                        data-testid="line-item-amount"
                                        value={`${item.item_amount}`}
                                        placeholder="Line item amount"
                                        readonly={!formValues.is_reseller}
                                        onChange={(e) => {
                                            handleUpdateLineItemAmount(
                                                e.detail.value
                                                    ? e.detail.value
                                                    : '0',
                                                itemIndex,
                                            );
                                        }}
                                    />
                                ),
                            },
                        ]}
                        addButtonText="Add line item"
                        removeButtonText="Remove"
                        isItemRemovable={() => formValues.is_reseller}
                        empty="No line items for this invoice."
                    />
                </div>
            </ColumnLayout>
        </FormSection>
    );
};
